.side-panel-wrap {
	position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
	&.open {
		.side-panel-overlay {
			opacity: 1;
			pointer-events: visible;
			backdrop-filter: blur(10px);
		}
	}
	.side-panel-overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 4;
		opacity: 0;
		transition: var(--transition-all-2ms);
		pointer-events: none;
	}
	&.open .side-panel {
		right: 0;
		opacity: 1;
		pointer-events: visible;
	}
	.side-panel {
		position: fixed;
		z-index: 5;
		top: 0;
		right: -350px;
		opacity: 0;
		pointer-events: none;
		height: 100%;
		width: 350px;
		max-width: 100%;
		padding: var(--space-4);
		border-left: 1px solid var(--color-border);
		background-color: var(--color-bg);
		transition: var(--transition-all-2ms);
		display: flex;
		flex-direction: column;
		overflow: auto;
		.side-panel-header {
			display: flex;
			align-items: center;
		}
		.side-panel-body {
			flex: 1;
		}
		.side-panel-footer {
			display: flex;
			justify-content: flex-end;
			padding-top: var(--space-4);
		}
	}
}
.page.side-panel-open .page-footer {
	pointer-events: none;
}
