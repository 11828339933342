.side-nav-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    pointer-events: none;
    background-color: var(--color-modal-bg);
    opacity: 0;
    transition: var(--transition-all-2ms);
    cursor: pointer;
    &.open {
        opacity: 1;
        pointer-events: visible;
    }
}
.side-nav-wrap {
    width: 300px;
    max-width: 80%;
    border-right: 1px solid var(--color-border);
    padding-right: var(--space-3);
    position: fixed;
    left: -300px;
    opacity: 0;
    pointer-events: none;
    top: 0;
    min-height: 100%;
    overflow: auto;
    z-index: 4;
    background-color: var(--color-bg);
    transition: var(--transition-all-2ms);
    .side-nav {
        list-style: none;
        margin: var(--space-3) 0;
        padding: 0;
        li {
            padding: var(--space-2) var(--space-3);
            cursor: pointer;
            font-size: 14px;
            border-radius: 0 var(--space-1) var(--space-1) 0;
            transition: var(--transition-all-1ms);
            margin-bottom: var(--space-1);
            &.active {
                cursor: default;
                background-color: var(--color-primary);
                color: var(--color-white);
            }
            &:hover {
                background-color: var(--color-primary);
                color: var(--color-white);
            }
        }
    }    
    &.open {
        left: 0px;
        opacity: 1;
        pointer-events: visible;
    }
}
.mobile-nav-btn {
    pointer-events: visible;
    cursor: pointer;
    left: 0;
    position: relative;
    top: 0;
    z-index: 3;
}
@media screen and (min-width: 1024px) {
    .side-nav-wrap {
        position: relative;
        left: 0px;
        opacity: 1;
        pointer-events: visible;
    }
    .mobile-nav-btn {
        display: none;
    }
}
