#modal-root {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;  
    > div {
        width: 100%;
        height: 100%;
    }
    .modal-wrap {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        transition: var(--transition-all-2ms);
        z-index: 4;
        display: flex;
        align-items: center;
        justify-content: center;  
        pointer-events: none;      
        &.open {
            pointer-events: visible;
            .modal-overlay {
                opacity: 1;
            }
            .modal-dialog {
                opacity: 1;
                transform: scale(1,1);
            }
        }
        .modal-overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.4);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transition: var(--transition-all-2ms);
            opacity: 0;
        }
        .modal-dialog {
            display: flex;
            flex-direction: column;
            padding: var(--space-3);
            opacity: 0;
            transform: scale(0.7,0.7);
            background-color: var(--color-modal-bg);
            width: 700px;
            min-height: 150px;
            box-shadow: 0 5px 5px rgba(0,0,0,0.1);
            border-radius: var(--space-2);
            position: relative;
            z-index: 2;
            transition: var(--transition-all-2ms);
            max-height: 90%;
            max-width: 90%;
            overflow: hidden;
            .modal-container {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                padding: var(--space-1);
                .modal-header {
                    padding: 0 0 var(--space-3) 0;
                }
                .modal-body {
                    overflow-y: auto;
                    overflow-x: hidden;
                }
                .modal-footer {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: var(--space-3);
                }
            }
        }
    }
}