.passcode-wrap {
    display: grid;
    place-content: center;
    width: 100%;
    height: 100%;
    .passcode-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        min-width: 300px;
        max-width: 90%;
        .passcode-field {
            margin: 0;
            flex: 1;
            .form-control {
                text-align: center;
            }
        }
        .passcode-account {
            display: flex;
            align-items: center;
            gap: 15px;
        }
        .passcode-fields {
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }
}
