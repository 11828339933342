#monthpicker-root {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    * {
        pointer-events: visible;
    }
}
.monthpicker-wrap {
    position: fixed;
    box-shadow: 0 0 0 var(--space-1) rgba(0, 0, 0, 0.2),
        0 5px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--color-form-control);
    border-radius: 10px;
    z-index: 100;
    width: 310px;
    height: unset;
    top: 0;
    left: 0;
    .monthpicker-container {
        display: flex;
        flex-direction: column;
        .monthpicker-header {
            padding: var(--space-3);
        }
        .monthpicker-body {
            display: flex;
            padding: 0 var(--space-3);
            .monthpicker-months {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .monthpicker-month {
                    width: 25%;
                    text-align: center;
                    cursor: pointer;
                    line-height: 18px;
                    border-radius: var(--space-2);
                    padding: var(--space-1) 0;
                    &.current {
                        color: var(--color-primary);
                        pointer-events: none !important;
                    }
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.1);
                    }
                    .month-digit {
                        font-size: 14px;
                    }
                    .month-name {
                        font-size: 11px;
                    }
                }
            }
            .monthpicker-years {
                width: 80px;
                height: 160px;
                border-left: 1px solid rgba(255, 255, 255, 0.2);
                text-align: center;
                display: flex;
                flex-direction: column;
                overflow: auto;
                .monthpicker-year {
                    line-height: 30px;
                    cursor: pointer;
                    &.current {
                        color: var(--color-primary);
                        pointer-events: none !important;
                    }
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.1);
                    }
                }
            }
        }
        .monthpicker-footer {
            padding: var(--space-3);
            display: flex;
            justify-content: flex-end;
            gap: var(--space-3);
        }
    }
}
