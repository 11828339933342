.tab-wrap {
    .tab-header {
        display: flex;
        margin-bottom: 15px;
        overflow-x: auto;
        overflow-y: hidden;
        gap: 15px;
        .tab-item {
            padding: 5px 0 2px 0;
            border-bottom: 2px solid transparent;
            font-size: 14px;
            cursor: pointer;
            &.active {
                border-bottom: 2px solid var(--color-primary);
            }
        }
    }
    .tab-body {
        .tab-item {
            display: none;
            &.active {
                display: block;
            }
        }
    }
}