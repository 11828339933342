.calendar {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: var(--transition-all-2ms);
    .calendar-head {
        display: flex;
        align-items: center;
        padding: 0 0 var(--space-2) 0;
        border-bottom: 1px solid var(--color-border);
        .head-month,
        .head-year {
            padding: 0 var(--space-1);
            cursor: pointer;
            margin-left: -5px;
        }
        .head-prev,
        .head-next {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            padding: 0;
            margin: 0 0 0 var(--space-1);
            cursor: pointer;
            position: relative;
            &:hover {                
                background-color: var(--color-light-hover);
            }
            &:before {
                content: '';
                display: block;
                width: 5px;
                height: 5px;
                border-bottom: 2px solid var(--color-text-light);
                position: absolute;
                top: 8px;
            }
        }
        .head-prev:before {
            border-left: 2px solid var(--color-text-light);
            transform: rotate(45deg);
            left: 10px;
        }
        .head-next:before {
            border-right: 2px solid var(--color-text-light);   
            transform: rotate(-45deg);
            left: 7px;       
        }
        .head-spacer {
            flex: 1;
        }
    }
    .calendar-days {
        display: flex;
        .calendar-day {
            width: 14.2857%;
            text-transform: uppercase;
            font-size: var(--space-2);
            color: var(--color-text-light);
            text-align: center;
            padding: var(--space-1) 0 0 0;
            &:not(:nth-child(7n+0)) {
                border-right: 1px solid var(--color-border);
            }
        }
    }
    .calendar-dates {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        position: relative;
        .dates-date {
            width: 14.2857%;
            height: 16.6666666667%;
            padding: 3px;
            cursor: pointer;
            transition: var(--transition-all-2ms);
            &:nth-child(7) ~ .dates-date {                
                border-top: 1px solid var(--color-border);
            }
            &:not(:nth-child(7n+0)) {
                border-right: 1px solid var(--color-border);
            }
            &.this-date .dates-label {
                background-color: var(--color-light-hover);
            }
            &.selected .dates-label {
                background-color: var(--color-primary);
                color: var(--color-bg);
            }
            .dates-label {
                text-align: center;
                font-size: 12px;
                line-height: 24px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin: 0 auto;
            }
            .dates-month-label {
                text-align: center;
                font-size: 9px;
                text-transform: uppercase;
                color: var(--color-text);
                opacity: 0.6;
            }
        }
    }
    .calendar-months {
        display: flex;
        flex-wrap: wrap;
        .month {
            width: 25%;
            cursor: pointer;
            border-radius: 10px;
            &:hover {
                background-color: var(--color-light-hover);
            }
            .month-label {
                padding: 10px;
                text-align: center;
                font-size: var(--space-2);
            }
            &.this-month {
                background-color: var(--color-light-hover);
            }
            &.selected {
                background-color: var(--color-primary);
                .month-label {
                    color: var(--color-bg);
                }
            }
        }
    }
    .calendar-years {
        display: flex;
        flex-wrap: wrap;
        .year {
            width: 20%;
            cursor: pointer;
            border-radius: 10px;
            &:hover {
                background-color: var(--color-light-hover);
            }
            &.this-year {
                background-color: var(--color-light-hover);
            }
            &.selected {
                background-color: var(--color-primary);
                .year-label {
                    color: var(--color-bg);
                }
            }
            .year-label {
                padding: 10px;
                font-size: var(--space-2);
                text-align: center;
            }
        }
        .years-prev-button,
        .years-next-button {
            width: 20%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            .button-label {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                position: relative;
                &:hover {                
                    background-color: var(--color-light-hover);
                }
                &:before {
                    content: '';
                    display: block;
                    width: 5px;
                    height: 5px;
                    border-bottom: 2px solid var(--color-text-light);
                    position: absolute;
                    top: 8px;
                }
            }
        }        
        .years-prev-button .button-label:before {
            border-left: 2px solid var(--color-text-light);
            transform: rotate(45deg);
            left: 10px;
        }
        .years-next-button .button-label:before {
            border-right: 2px solid var(--color-text-light);   
            transform: rotate(-45deg);
            left: 7px;       
        }
    }
}