.pdf-viewer-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    nav {
        display: flex;
        gap: var(--space-2);
        align-items: center;
        position: absolute;
        bottom: 20px;
        left: calc(50% - 150px);
        z-index: 5;
        .page-details {
            width: 100px;
            text-align: center;
            background-color: var(--color-light-hover);
            border-radius: var(--space-2);
            padding: 2px 0;
        }
        .close-pdf-viewer i {
            font-size: 12px;
        }
    }
    .pdf-loader {
        max-height: 100%;
        overflow: auto;
        padding: var(--space-4);
        .react-pdf__Page__textContent.textLayer,
        .annotationLayer {
            display: none;
        }
    }
}