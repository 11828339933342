@import "./assets/icomoon/style.css";

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.2s ease-in-out;
}

* {
    box-sizing: border-box;
    outline: none;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}
@media (hover: hover) {
    // * {
    //     scrollbar-width: thin;
    //     scrollbar-color: rgba(127, 127, 127, 0.4) rgba(127, 127, 127, 0.1);
    //     scroll-behavior: smooth;
    // }
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background-color: rgba(127, 127, 127, 0.1);
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgba(127, 127, 127, 0.4);
        outline: none;
        cursor: pointer;
    }
    ::-webkit-scrollbar-corner {
        background-color: rgba(127, 127, 127, 0.1);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

[class^="icon-"],
[class*=" icon-"] {
    display: block;
    font-size: 16px;
}

html,
body {
    font-size: 12px;
    height: 100%;
    overflow: hidden;
    line-height: 22px;
}

body {
    background-color: var(--color-bg);
}

.font {
    &-size {
        @each $size in 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
            38, 40
        {
            &-#{$size} {
                font-size: #{$size}px;
            }
        }
    }
}

#tooltip-root {
    position: fixed;
    z-index: 50;
    width: 100%;
    height: 100%;
    pointer-events: none;
    .tooltip-wrap {
        position: absolute;
        padding: var(--space-1) var(--space-2);
        border-radius: 3px;
        background-color: var(--color-bg-invert);
        color: var(--color-bg);
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
        max-width: 180px;
        line-height: 16px;
        .tooltip-arrow {
            content: "";
            width: 0px;
            height: 0px;
            border: 5px solid transparent;
            display: block;
            position: absolute;
            margin-left: -5px;
            margin-right: -5px;
        }
        &.arrow-top .tooltip-arrow {
            top: 100%;
            border-top-color: var(--color-bg-invert);
        }
        &.arrow-bottom .tooltip-arrow {
            bottom: 100%;
            border-bottom-color: var(--color-bg-invert);
        }
    }
}
.tooltip-source {
    display: inline-block;
}
.tooltip-source.disable-child > * {
    pointer-events: none;
}
.tooltip-source > * > * {
    pointer-events: none;
}
#modal-root ~ #root {
    filter: blur(5px);
}
#root {
    height: 100%;
}

h1,
.h1 {
    font-size: 30px !important;
    margin: 0 0 var(--space-3) 0;
}
h2,
.h2 {
    font-size: 24px !important;
    margin: 0 0 var(--space-3) 0;
}
h3,
.h3 {
    font-size: 20px !important;
    margin: 0 0 var(--space-3) 0;
}
h4,
.h4 {
    font-size: 16px !important;
    margin: 0 0 var(--space-3) 0;
}
h5,
.h5 {
    font-size: 14px !important;
    margin: 0 0 var(--space-3) 0;
}
h6,
.h6 {
    font-size: 12px !important;
    margin: 0 0 var(--space-3) 0;
}

@for $i from 0 through 5 {
    $value: calc(var(--space-1) * #{$i});
    .m-#{$i} {
        margin: $value !important;
    }
    .p-#{$i} {
        padding: $value !important;
    }
}
@for $i from 0 through 5 {
    @each $property in top, left, bottom, right {
        $suffix: str-slice($property, 1, 1);
        $value: calc(var(--space-1) * #{$i});
        .m#{$suffix}-#{$i} {
            margin-#{$property}: $value !important;
        }
        .p#{$suffix}-#{$i} {
            padding-#{$property}: $value !important;
        }
    }
}
@for $i from 0 through 5 {
    $value: calc(var(--space-1) * #{$i});
    .mx-#{$i} {
        margin-left: $value !important;
        margin-right: $value !important;
    }
    .px-#{$i} {
        padding-left: $value !important;
        padding-right: $value !important;
    }
    .my-#{$i} {
        margin-top: $value !important;
        margin-bottom: $value !important;
    }
    .py-#{$i} {
        padding-top: $value !important;
        padding-bottom: $value !important;
    }
}

.d {
    &-none {
        display: none !important;
    }
    &-flex {
        display: flex !important;
        align-items: center;
        &.align {
            &-start {
                align-items: flex-start;
            }
            &-center {
                align-items: center;
            }
            &-end {
                align-items: flex-end;
            }
        }
        &.justify {
            &-start {
                justify-content: flex-start;
            }
            &-center {
                justify-content: center;
            }
            &-end {
                justify-content: flex-end;
            }
            &-around {
                justify-content: space-around;
            }
            &-between {
                justify-content: space-between;
            }
            &-evenly {
                justify-content: space-evenly;
            }
        }
    }
}
.flex-fill {
    flex: 1;
}
.gap {
    @each $size in 0, 1, 2, 3, 4, 5 {
        &-#{$size} {
            gap: var(--space-#{$size});
        }
    }
}

.text {
    @each $pos in left, center, right {
        &-#{$pos} {
            text-align: #{$pos} !important;
        }
    }
    &-label {
        font-size: 11px;
        padding-bottom: 3px;
        opacity: 0.6;
        pointer-events: none;
    }
    &-primary {
        color: var(--color-primary);
    }
    &-danger {
        color: var(--color-error);
    }
    &-success {
        color: var(--color-success);
    }
    &-capitalize {
        text-transform: capitalize;
    }
    &-uppercase {
        text-transform: uppercase;
    }
    &-paragraph {
        text-transform: lowercase;
        &:first-letter {
            text-transform: uppercase;
        }
    }
}

.overflow {
    &-auto {
        overflow: auto;
    }
    &-hidden {
        overflow: hidden;
    }
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.action-disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}

.cursor {
    &-pointer {
        cursor: pointer;
    }
}

@keyframes anim-light {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
}
@keyframes anim-dark {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}
:root {
    --transition-all-1ms: all 0.1s ease-in-out;
    --transition-all-2ms: all 0.2s ease-in-out;
    --space-1: 5px;
    --space-2: 10px;
    --space-3: 15px;
    --space-4: 20px;
    --space-5: 25px;
    --space-6: 30px;
    --space-7: 35px;
    --space-8: 40px;

    --color-white: #fff;
    --color-bg: #888;
    --color-error: #e74c3c;
    --color-success: #53a42e;
    --color-male: #5d7fd5;
    --color-female: #cb5fb6;

    --bg-month-jan: rgba(244, 67, 54, 0.1);
    --bg-month-feb: rgba(233, 30, 99, 0.1);
    --bg-month-mar: rgba(103, 58, 183, 0.1);
    --bg-month-apr: rgba(63, 81, 181, 0.1);
    --bg-month-may: rgba(33, 150, 243, 0.1);
    --bg-month-jun: rgba(0, 188, 212, 0.1);
    --bg-month-jul: rgba(0, 150, 136, 0.1);
    --bg-month-aug: rgba(76, 175, 80, 0.1);
    --bg-month-sep: rgba(255, 235, 59, 0.1);
    --bg-month-oct: rgba(255, 152, 0, 0.1);
    --bg-month-nov: rgba(121, 85, 72, 0.1);
    --bg-month-dec: rgba(96, 125, 139, 0.1);

    --shadow-primary: 0 10px 20px rgba(30, 136, 229, 0.2);
    --shadow-dark: 0 10px 20px rgba(0, 0, 0, 0.075);

    @for $i from 1 through 12 {
        $value: calc(8.3333% * #{$i});
        --col-#{$i}: #{$value};
    }

    --z-index-modal: 100;
    --z-index-main-nav: 90;
}
.app-light {
    --color-bg: #fff;
    --color-bg-80: rgba(255, 255, 255, 0.8);
    --color-primary: #1e88e5;
    --color-secondary: #546e7a;
    --color-bg-invert: #1e1e1e;
    --color-border: #dadce0;
    --color-text: #000000;
    --color-text-light: rgba(0, 0, 0, 0.6);
    --color-light-hover: #ececec;
    --color-form-control: #e5eaed;
    --color-form-control-hover: #d9dfe2;
    --color-modal-bg: #f5f5f5;

    color: var(--color-text);
    .toggle-theme .button-toggle-dot {
        animation: anim-dark 0.5s ease-in-out;
    }
}
.app-dark {
    --color-bg: #1e1e1e;
    --color-bg-80: rgba(30, 30, 30, 0.8);
    --color-primary: #1e88e5;
    --color-secondary: #546e7a;
    --color-bg-invert: #fff;
    --color-border: #333333;
    --color-text: #ffffff;
    --color-text-light: rgba(255, 255, 255, 0.6);
    --color-light-hover: #29353b;
    --color-form-control: #444452;
    --color-form-control-hover: #50505f;
    --color-modal-bg: #2e2f3a;

    color: var(--color-text);
    .toggle-theme {
        .button-toggle-dot {
            animation: anim-light 0.5s ease-in-out;
        }
    }
    button {
        color: var(--color-text);
    }
}

.memo-vijee-in {
    position: relative;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    transition: var(--transition-all-2ms);
}

a {
    color: #1e88e5;
    text-decoration: none;
}

.divider {
    border-top: 1px solid var(--color-border);
}
.divider-secondary {
    border-top: 1px solid var(--color-secondary);
}
.divider-primary {
    border-top: 1px solid var(--color-primary);
}

.page-header-logo {
    width: 26px;
    height: 26px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 26px;
    color: var(--color-white);
    cursor: pointer;
    position: relative;
    &.logo-calendar {
        background-image: url("./assets/images/logo-calendar.png");
    }
    &.logo-transaction {
        background-image: url("./assets/images/logo-transactions.png");
    }
    &.logo-events {
        background-image: url("./assets/images/logo-events.png");
    }
    &.logo-dues {
        background-image: url("./assets/images/logo-dues.png");
    }
    &.logo-payslips {
        background-image: url("./assets/images/logo-payslips.png");
    }
    &.logo-notes {
        background-image: url("./assets/images/logo-notes.png");
    }
    &.logo-ancestry {
        background-image: url("./assets/images/logo-ancestry.png");
    }
    &.logo-settings {
        background-image: url("./assets/images/logo-settings.png");
    }
    &.logo-photos {
        background-image: url("./assets/images/logo-photos.png");
    }
    &.logo-financial-calculator {
        background-image: url("./assets/images/logo-financial-calculations.png");
    }
    &.logo-todo {
        background-image: url("./assets/images/logo-todo.png");
    }
    &.logo-contacts {
        background-image: url("./assets/images/logo-contacts.png");
    }
    &.logo-vault {
        background-image: url("./assets/images/logo-vault.png");
    }
    .logo-date {
        position: absolute;
        left: 0;
        top: 5px;
        text-align: center;
        width: 26px;
        color: var(--color-white);
    }
}

.btn {
    border-radius: 17px;
    border: none;
    min-height: 34px;
    padding: 0 var(--space-4);
    cursor: pointer;
    font-size: 12px;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--space-2);
    &-primary {
        background-color: #1e88e5;
        color: #ffffff;
        &:hover {
            background-color: #167bd3;
        }
        &:focus {
            box-shadow: 0 0 0 var(--space-1) rgba(30, 136, 229, 0.4);
        }
    }
    &-secondary {
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid #dadce0;
        &:hover {
            background-color: var(--color-light-hover);
        }
        &:focus {
            box-shadow: 0 0 0 var(--space-1) rgba(255, 255, 255, 0.1);
        }
    }
    &-light {
        background-color: transparent;
        &:hover {
            background-color: var(--color-light-hover);
        }
    }
    &-danger {
        background-color: var(--color-error);
        border: 1px solid var(--color-error);
        color: var(--color-bg);
        &:hover {
            background-color: var(--color-error);
        }
    }

    &-cell {
        width: 34px;
        height: 34px;
        padding: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-xs.btn-cell {
        width: 20px;
    }
    &-sm.btn-cell {
        width: 24px;
    }
    &-md.btn-cell {
        width: 28px;
    }
    &-lg.btn-cell {
        width: 38px;
    }
    &-xl.btn-cell {
        width: 44px;
    }

    &-xs {
        height: 20px;
        min-height: 20px;
    }
    &-sm {
        height: 24px;
        min-height: 24px;
    }
    &-md {
        height: 28px;
        min-height: 28px;
    }
    &-lg {
        height: 38px;
        min-height: 38px;
    }
    &-xl {
        height: 44px;
        min-height: 44px;
    }

    &:disabled,
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &.disabled {
        pointer-events: none;
    }
}

.app-dark {
    .form-group .form-field .form-control {
        color: #ffffff;
    }
    .btn-secondary {
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: #ffffff;
        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}

.page {
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .page-header {
        display: flex;
        gap: var(--space-3);
        align-items: center;
        min-height: 54px;
        padding: 0 var(--space-3);
        border-bottom: 1px solid var(--color-border);
    }
    .page-body {
        display: flex;
        flex: 1;
        .page-content {
            flex: 1;
        }
    }
}

.badge {
    padding: 0px 6px;
    display: inline-block;
    margin: 0 3px;
    border-radius: 4px;
    font-size: 85%;
    line-height: 16px;
    background-color: var(--color-light-hover);
    &:first-child {
        margin-left: 0;
    }
}

@mixin align-justify {
    &.align {
        &-start {
            align-items: flex-start;
        }
        &-center {
            align-items: center;
        }
        &-end {
            align-items: flex-end;
        }
        &-stretch {
            align-items: stretch;
        }
    }
    &.justify {
        &-start {
            justify-content: flex-start;
        }
        &-center {
            justify-content: center;
        }
        &-end {
            justify-content: flex-end;
        }
        &-stretch {
            justify-content: stretch;
        }
        &-between {
            justify-content: space-between;
        }
    }
}

.row {
    display: flex;
    gap: var(--space-3);
    flex-wrap: wrap;
    width: calc(100% + var(--space-3));
    flex-direction: row;
    @include align-justify;
    .col {
        flex: 1;
    }
    @for $i from 1 through 12 {
        .col-#{$i} {
            width: calc(var(--col-#{$i}) - var(--space-3));
        }
    }
}

.overflow-hidden {
    overflow: hidden;
}

.word-break {
    &-all {
        word-break: break-all;
    }
}

@media screen and (min-width: 768px) {
    .memo-vijee-in {
        flex-direction: row;
    }
    .row {
        @for $i from 1 through 12 {
            .col-md-#{$i} {
                width: calc(var(--col-#{$i}) - var(--space-3));
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .side-nav-btn {
        display: none;
    }
}

.day-selector {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    .day {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--color-form-control);
        text-align: center;
        cursor: pointer;
        line-height: 30px;
        &.selected {
            background-color: var(--color-primary);
            color: var(--color-bg);
        }
    }
}

.quill-content {
    p {
        min-height: 22px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    strong {
        font-weight: 600;
    }
    span.password {
        cursor: pointer;
    }
    &.show-password span.password {
        -webkit-text-security: unset !important;
    }
}
