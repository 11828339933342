.form-group.time-picker {
    .form-control {
        font-size: 16px;
        text-align: center;
        position: relative;
        &.time-hours,
        &.time-minutes {
            margin-right: 10px;
            cursor: pointer;
        }
        &.time-am-pm {
            padding-right: 40px;
            cursor: pointer;
        }
        .time-items {
            position: absolute;
            z-index: 2;
            top: -40px;
            left: 0;
            width: 100%;
            height: 115px;
            background-color: var(--color-form-control);
            border-radius: 4px;
            overflow: auto;
            scroll-snap-type: y mandatory;
            .time-item {
                height: 32px;
                line-height: 32px;
                scroll-snap-align: start;
                &.active,
                &:hover {
                    background-color: var(--color-form-control-hover);
                }
            }
        }
    }
}