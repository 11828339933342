.passcode-input-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .passode-input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .passode-input {
            width: 50px;
            height: 50px;
            text-align: center;
            font-size: 24px;
            letter-spacing: 2px;
            border-radius: 10px;
            border: none;
            background-color: rgba(0,0,0,0.1);
            color: var(--color-text);
            caret-color: transparent;
            box-shadow: 0 0 15px rgba(0,0,0,0.2) inset, 0 5px 20px rgba(255,255,255,0.01);
            &:focus {
                background-color: rgba(0,0,0,0.2);
                box-shadow: 0 0 15px rgba(0,0,0,0.2) inset, 0 5px 20px rgba(30, 136, 229, 0.2);
            }
        }
    }
}
