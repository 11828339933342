.field-group {
    display: flex;
    gap: var(--space-3);
    & > .form-group {
        flex: 1;
    }
}
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &.input-radio,
    &.input-checkbox {
        & > .form-label {
            opacity: 0;
            pointer-events: none;
        }
    }
    &.no-label .form-label {
        display: none;
    }
    .form-label {
        font-size: 11px;
        padding-bottom: 3px;
        opacity: 0.6;
    }
    .form-field {
        position: relative;
        .form-control {
            width: 100%;
            border: none;
            border-radius: 4px;
            background-color: var(--color-form-control);
            padding: 5px 10px;
            min-height: 34px;
            transition: all 0.1s ease-in-out;
            &.invalid {
                box-shadow: 0 0 0 2px var(--color-error) inset;
                animation: field-invalid-anim 0.2s ease-in-out;
            }
            &.disabled,
            &:disabled {
                opacity: 0.4;
            }
            @keyframes field-invalid-anim {
                0% {
                    transform: translateX(0px);
                }
                30% {
                    transform: translateX(-5px);
                }
                70% {
                    transform: translateX(5px);
                }
                0% {
                    transform: translateX(0px);
                }
            }
        }
        textarea.form-control {
            min-height: 106px;
            resize: vertical;
        }
        .icon {
            position: absolute;
            right: 5px;
            bottom: 8px;
            font-size: 20px;
        }
        .hidden-control {
            input {
                display: none;
                & ~ .form-label {
                    display: flex;
                    align-items: center;
                    margin-top: 6px;
                    opacity: 1;
                    cursor: pointer;
                    position: relative;
                    gap: var(--space-2);
                    .input-ui {
                        width: 20px;
                        height: 20px;
                        border-radius: 5px;
                        background-color: var(--color-form-control);
                        border: 2px solid transparent;
                        display: block;
                        position: relative;
                        transition: var(--transition-all-2ms);
                    }
                }
                &:hover ~ .form-label .input-ui {
                    border: 2px solid var(--color-primary);
                }
                &[type="checkbox"]:checked ~ .form-label .input-ui {
                    background-color: var(--color-primary);
                }
                &[type="checkbox"] ~ .form-label .input-ui:before {
                    content: "";
                    width: 4px;
                    height: 10px;
                    border-bottom: 2px solid var(--color-bg);
                    border-right: 2px solid var(--color-bg);
                    transform: rotate(45deg);
                    position: absolute;
                    top: 0px;
                    left: 5px;
                    transition: var(--transition-all-2ms);
                }
                &[type="radio"] ~ .form-label .input-ui {
                    border-radius: 10px;
                }
                &[type="radio"]:checked ~ .form-label .input-ui {
                    border: 7px solid var(--color-primary);
                }
            }
        }
    }
    .form-error {
        display: none;
        font-size: 12px;
    }
    .quill {
        .ql-toolbar.ql-snow {
            border: none;
            border-bottom: 1px solid var(--color-bg);
            background-color: var(--color-form-control);
            border-radius: 5px 5px 0 0;
        }
        .ql-container.ql-snow {
            border: none;
            background-color: var(--color-form-control);
            border-radius: 0 0 5px 5px;
        }
        .ql-snow .ql-stroke {
            stroke: var(--color-text);
        }
        .ql-snow .ql-fill {
            fill: var(--color-text);
        }
        .ql-snow .ql-picker {
            color: var(--color-text);
        }
        span.password {
            background-color: rgba(255,0,0,0.1);
        }
    }
}
