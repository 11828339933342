.taggable-wrapper {
    width: 100%;
    .taggable-container {
        font-size: 16px;
        line-height: 34px;
        padding: var(--space-5);
        border: 1px solid var(--color-border);
        border-radius: var(--space-2);
        .tag {
            background-color: var(--color-secondary);
            padding: 0 var(--space-1) 3px var(--space-1);
            border-radius: var(--space-1);
            cursor: pointer;
        }
    }
}
