.app-header {
	transition: all 0.2s ease-in-out;
    min-height: 54px;
    border-bottom: 1px solid var(--color-border);
    display: flex;
    align-items: center;
    .header-container {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 var(--space-3);
        .memo-app-nav-wrap {
            margin-right: var(--space-3);
        }
        .header-menu {
            display: flex;
        }
        .header-today,
        .header-next,
        .header-month {
            margin-right: var(--space-2);
        }
        .header-search {
            margin-right: 0;
        }
        .header-month,
        .header-year {
            font-size: 16px;
        }
        .header-spacer {
            flex: 1;
        }
        .header-search {            
            width: 32px;
            height: 32px;
            padding: 0;
            border-radius: 16px;
            text-align: center;
            line-height: 32px;
            display: flex;
            position: relative;
            &.open {
                width: 200px;
                background-color: var(--color-modal-bg);
                .form-group {
                    display: block;
                }
            }
            .icon-close {
                font-size: 12px;
            }
            .form-group {
                flex: 1;
                display: none;
                margin-left: 5px;
                .form-control {
                    border-radius: 16px;
                    background-color: transparent;
                }
            }
            .search-result-wrap {
                position: absolute;
                left: 0;
                top: calc(100% + 2px);
                width: 100%;
                z-index: 10;
                border-radius: 8px;
                background-color: var(--color-modal-bg);
                box-shadow: 0 5px 5px rgba(0,0,0,0.1);
                padding: 5px;
                .result-item {
                    display: flex;
                    align-items: center;
                    text-align: left;
                    padding: 5px 10px;
                    cursor: pointer;
                    border-radius: 5px;
                    &:hover {
                        background-color: var(--color-light-hover);
                    }
                    .details {
                        flex: 1;
                        margin-right: 5px;
                        .result-item-label {
                            line-height: 12px;
                            font-size: 10px;
                            padding-bottom: 3px;
                            opacity: 0.6;
                        }
                        .result-item-name {
                            line-height: 18px;
                        }
                    }
                    .icon {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 10px;
                        color: var(--color-bg);
                    }
                }
            }
        }
        .focus-event-badge {
            margin-right: var(--space-2);
            border-radius: 10px;
            background-color: var(--color-border);
            display: flex;
            gap: var(--space-2);
            padding: 0 var(--space-2);
            align-items: center;
            .label {
                cursor: pointer;
                font-size: 10px;
                &:hover {
                    color: var(--color-secondary);
                }
            }
            .close {
                cursor: pointer;
                .icon-close {
                    font-size: 6px;
                }
                &:hover {
                    color: var(--color-error);
                }
            }
        }
        .header-today,
        .header-prev,
        .header-next,
        .header-month,
        .header-year,
        .header-time {
            display: none;
        }
    }
}

@media screen and (min-width: 1024px) {
    .app-header .header-container {
        .header-menu {
            display: none;
        }
        .header-menu {
            margin-right: var(--space-2);
        }        
        .header-today {
            display: block;
        }
        .header-prev,
        .header-next,
        .header-month,
        .header-year,
        .header-time {
            display: flex;
        }
        .header-search {
            margin-right: var(--space-2);
            &.open {
                width: 250px;
            }
        }
    }
}
