#timepickeranalog-root {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    * {
        pointer-events: visible;
    }
}
.analog-clock-wrap {
    width: 24px;
    height: 24px;
    position: relative;
}
.analog-clock {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--color-bg);
    position: absolute;
    cursor: pointer;
    z-index: 10;
    top: 0;
    left: 0;
    padding: 0;
    border: none;
    @keyframes scale-anim {
        from {
            opacity: 0;
            transform: scale(0.1);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
    .hours {
        width: 2px;
        height: 8px;
        border-radius: 1px;
        background-color: #1E88E5;
        position: absolute;
        left: 11px;
        top: 4px;
        transform-origin: bottom center;
    }
    .minutes {
        width: 2px;
        height: 10px;
        border-radius: 1px;
        background-color: #7FBFF6;
        position: absolute;
        left: 11px;
        top: 2px;
        transform-origin: bottom center;
    }
    
}
.analog-clock-picker {
    animation: scale-anim 0.2s ease-in-out;
    padding: var(--space-2);
    box-shadow: 0 0 0 var(--space-1) rgba(0,0,0,0.2), 0 5px 5px rgba(0,0,0,0.1);
    background-color: var(--color-form-control);
    position: absolute;
    border-radius: var(--space-2);
    .fields {
        display: flex;
        gap: 5px;
        .field {
            width: 36px;
            padding: var(--space-1);
            border-radius: var(--space-1);
            border: 1px solid var(--color-border);
            background-color: var(--color-bg);
            cursor: text;
            text-align: center;
            color: var(--color-text);
        }
    }
}