#memo-app-nav-root {
    position: fixed;
    z-index: var(--z-index-main-nav);
    left: 0;
    top: 0;
    pointer-events: none;
    height: 100%;
    .memo-app-nav-container {
        border-right: 1px solid var(--color-border);
        background-color: var(--color-modal-bg);
        pointer-events: none;
        opacity: 0;
        transform: scale(0.8);
        transform-origin: 0 0;
        transition: var(--transition-all-1ms);
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        &.open {
            opacity: 1;
            pointer-events: visible;
            transform: scale(1);
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
        .divider {
            flex: 1;
        }
        .memo-app-nav-item {
            padding: var(--space-3) var(--space-4);
            display: flex;
            gap: var(--space-3);
            align-items: center;
            position: relative;
            width: 300px;
            transition: var(--transition-all-1ms);
            &:not(.active) {
                cursor: pointer;
                &:hover {
                    background-color: var(--color-form-control-hover);
                }
            }
            img {
                width: 26px;
                height: 26px;
                display: block;
            }
            span {
                font-size: 14px;
            }
            .icon {
                width: 26px;
                height: 26px;
                text-align: center;
                line-height: 26px;
            }
            &.menu-calendar .calendar-date {
                position: absolute;
                left: 20px;
                top: 20px;
                text-align: center;
                width: 26px;
                color: var(--color-white);
            }
            &.menu-logout-confirm {
                padding: var(--space-2) var(--space-4);
                min-height: 56px;
                transition: none;
                .are-you-sure {
                    line-height: 18px;
                }
            }
        }
    }
}
