.table-wrap {
	table {
		width: 100%;
		border: none;
    	border-collapse: collapse;
		thead {
			position: sticky;
			top: 0;
			background-color: var(--color-bg);
			box-shadow: 0 -2px 0 -1px var(--color-border) inset;
		}
		tr {
			th, td {
				padding: 10px;
				.th-container {
					display: flex;
					align-items: center;
					gap: 10px;
					&.sortable {
						cursor: pointer;
					}
					.th-sort .icon-sortable {
						color: var(--color-border);
					}
					.th-sort .icon-sort-asc,
					.th-sort .icon-sort-desc {
						color: var(--color-primary);
					}
				}
			}
			&:hover {
				background-color: rgba(255,255,255,0.05);
			}
		}
	}
}
.table-wrap.table-responsive {
	table {
		width: 100%;
		padding: var(--space-3);
        display: block;
        colgroup {
            display: none;
        }
        thead {
            display: none;
        }
        tbody {
            display: flex;
            flex-direction: column;
            gap: var(--space-3);
        }
        tr {
            display: flex;
            flex-direction: column;
            gap: var(--space-2);
            padding: var(--space-2);
            border-radius: var(--space-1);
            background-color: var(--color-modal-bg);
        }
		th {
			text-transform: capitalize;
            display: block;
		}
        td {            
            display: block;
            line-height: 18px;
            &:before {
                content: attr(data-label);
                display: block;
                font-size: 10px;
                color: var(--color-text-light);
            }
        }
	}
}

@media screen and (min-width: 768px) {
    .table-wrap.table-responsive {
        table {
            tbody {
                flex-direction: row;
                flex-wrap: wrap;
                tr {
                    width: calc(50% - var(--space-2));
					td:before {
						display: none;
					}
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
	.table-wrap.table-responsive {
		table {
			width: 100%;
			display: table;
			border-collapse: collapse;
			padding: 0;
            colgroup {
                display: table-column-group;
            }
            thead {
                display: table-header-group;
            }
            tbody {
                display: table-row-group;
            }
            tfoot {
                display: table-footer-group;
            }
			thead,
			tfoot {
				tr {
					background-color: var(--color-light-hover);
					position: sticky;
					top: 0;
					z-index: 2;
					th, td {
						padding: var(--space-2);
						font-size: 16px;
						border-top: none;
					}
				}
			}
			tr {
                display: table-row;
				position: relative;
                background-color: transparent;
				th,
				td {
					border-bottom: 1px solid var(--color-border);
					padding: 8px var(--space-2);
                    display: table-cell;
				}
				&.tr-footer {
					background-color: var(--color-light-hover);
					th, td {
						position: sticky;
						bottom: 0;
						z-index: 2;
						border-bottom: none;
					}
				}
			}
			tbody tr:hover {
				background-color: rgba(0,0,0,0.15);
			}
			thead {
				.th-container {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: var(--space-2);
					&.sortable {
						cursor: pointer;
					}
					.icon-sortable,
					.icon-sort-asc,
					.icon-sort-desc {
						font-size: 12px;
					}
					.icon-sortable {
						opacity: 0.5;
					}
				}
			}
			tbody th {
				background-color: var(--color-light-hover);
				opacity: 0.6;
				font-size: 14px;
			}
			[draggable] {
				cursor: move;
			}
		}
	}
}
