#datepicker-root {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    * {
        pointer-events: visible;
    }
}
.datepicker-calendar {
    position: fixed;
    box-shadow: 0 0 0 var(--space-1) rgba(0,0,0,0.2), 0 5px 5px rgba(0,0,0,0.1);
    background-color: var(--color-form-control);
    border-radius: 10px;
    z-index: 100;
    width: 220px;
    height: unset;
    top: 0;
    left: 0;
    .calendar-head {
        padding: 10px;
        border: none;
    }
    .calendar-days .calendar-day {
        font-size: 0;
        padding: 0;
        &:not(:nth-child(7n+0)) {
            border: none;
        }
        &:first-letter {
            font-size: 9px;
        }
    }
    .calendar-dates .dates-date {
        min-height: unset;
        padding: 0;
        &:nth-child(7) ~ .dates-date {
            border: none;
        }
        &:not(:nth-child(7n+0)) {
            border: none;
        }
        .dates-label {
            font-size: 10px;
        }
    }
}