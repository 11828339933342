#select-box-root {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    * {
        pointer-events: visible;
    }
}
.select-box-wrap {
    position: relative;
    .select-box-toggle {
        .toggle-label {
            font-size: 11px;
            padding-bottom: 3px;
            opacity: 0.6;
            pointer-events: none;
        }
        .toggle-value {
			padding: 5px 30px 5px 10px;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
        }
        .icon-chevron-up,        
        .icon-chevron-down {
            position: absolute;
            right: 7px;
            bottom: 11px;
            pointer-events: none;
            font-size: 12px;
        }
    }
}


.select-box-list-wrap {
    position: fixed;
    min-width: 200px;
    z-index: 100;
    background-color: var(--color-bg);
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    padding: 10px;
    border-radius: 5px;
    .select-box-search {
        position: relative;
        .form-group {
            margin-bottom: 10px;
        }
        .create-event-type-button {
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }
    .select-box-list {
        .list-item {
            padding: 3px 5px;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
                background-color: var(--color-light-hover);
            }
        }
    }
}