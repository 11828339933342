.loading-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .loading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--space-1);
        padding: var(--space-2) 0;
        .span-1,
        .span-2,
        .span-3 {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--color-border);
            display: block;
            transition: var(--transition-all-1ms);
        }
        .span-1 {
            animation: span-one 1s ease-in-out infinite;
        }
        .span-2 {
            animation: span-two 1s ease-in-out infinite;
        }
        .span-3 {
            animation: span-three 1s ease-in-out infinite;
        }
    }
}

@keyframes span-one {
    0% {
        background-color: var(--color-border);
    }
    20% {
        background-color: var(--color-primary);
    }
    40% {
        background-color: var(--color-border);
    }
    100% {
        background-color: var(--color-border);
    }
}
@keyframes span-two {
    0% {
        background-color: var(--color-border);
    }
    40% {
        background-color: var(--color-border);
    }
    60% {
        background-color: var(--color-primary);
    }
    80% {
        background-color: var(--color-border);
    }
    100% {
        background-color: var(--color-border);
    }
}
@keyframes span-three {
    0% {
        background-color: var(--color-border);
    }
    60% {
        background-color: var(--color-border);
    }
    80% {
        background-color: var(--color-primary);
    }
    100% {
        background-color: var(--color-border);
    }
}