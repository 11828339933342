.button-toggle {
	width: 30px;
	height: 20px;
	border: none;
	border-radius: 10px;
	background-color: var(--color-border);
	cursor: pointer;
	position: relative;
    transition: all 0.2s ease-in-out;
	.button-toggle-dot {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: var(--color-primary);
		display: block;
		position: absolute;
		left: 2px;
		top: 2px;
		transition: all 0.2s ease-in-out;
		opacity: 0.5;
	}
	&.on .button-toggle-dot {
		left: 12px;
		opacity: 1;
	}
}