.alert-wrap {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    padding: var(--space-3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--space-3);
}
.alert {
    pointer-events: visible;
    display: flex;
    gap: var(--space-3);
    padding: var(--space-2) var(--space-4);
    align-items: center;
    border-radius: var(--space-2);
    &.success {
        background-color: rgba(109, 204, 21, 0.2);
    }
    &.danger {
        background-color: rgba(204, 21, 21, 0.2);
    }
    &.info {
        background-color: rgba(21, 146, 204, 0.2);
    }
    .alert-close {
        cursor: pointer;
    }
}
