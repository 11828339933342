.confirm-box {
    .confirm-yes {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
    }
    .confirm-no {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: none;
    }
    .icon-close {
        font-size: 12px;
    }
}