#dropdown-root {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    * {
        pointer-events: visible;
    }
}
.dropdown-body {
    position: absolute;
    width: 200px;
    background-color: var(--color-modal-bg);
    box-shadow: 0 0 0 var(--space-1) rgba(0, 0, 0, 0.2), 0 5px 5px rgba(0, 0, 0, 0.1);
    padding: var(--space-3);
    border-radius: var(--space-2);
}
