.time-wrap {
    font-size: 16px;
    display: flex;
    text-align: center;
    .time-hour,
    .time-minute,
    .time-second,
    .time-ampm {
        width: 30px;
        position: relative;
        line-height: 20px;
    }
    .time-hour:after,
    .time-minute:after {
        content: ':';
        display: block;
        position: absolute;
        right: -1px;
        top: -1px;
    }
    .time-ampm {
        font-size: 12px;
        text-transform: uppercase;
    }
}