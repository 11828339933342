.app-nav {
    width: 250px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
    background-color: var(--color-bg);
    height: 100%;
    border-right: 1px solid var(--color-border);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateX(-250px);
    pointer-events: none;
    transition: var(--transition-all-2ms);
    overflow: auto;
    .nav-container {
        min-height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        .nav-calendar {
            border: none;
            padding: var(--space-3);
            .calendar-head {
                border: none;
            }
            .calendar-days .calendar-day {
                font-size: 0;
                &:first-letter {
                    font-size: var(--space-2);
                }
                &:not(:nth-child(7n + 0)) {
                    border: none;
                }
            }
            .calendar-dates {
                .dates-date:not(:nth-child(7n + 0)),
                .dates-date:nth-child(7) ~ .dates-date {
                    border: none;
                }
                .dates-date {
                    border-radius: 50%;
                    .dates-label {
                        font-size: var(--space-2);
                        line-height: 24px;
                    }
                }
            }
        }
        .calendar-view {
            padding: var(--space-3);
            border-top: 1px solid var(--color-border);
            .calendar-view-container {
                display: flex;
                padding-top: 5px;
                gap: var(--space-2);
            }
        }
        .calendar-filter {
            border-top: 1px solid var(--color-border);
            padding: var(--space-3);
            .filter-wrap {
                .filter-item {
                    .form-group {
                        margin-bottom: 0px;
                        & > .form-label {
                            display: none;
                        }
                        .input-ui ~ .input-label {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}
.page.nav-open .app-nav {
    opacity: 1;
    transform: translateX(0px);
    pointer-events: visible;
}
.app-nav-bg {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: var(--color-bg);
    opacity: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: var(--transition-all-1ms);
}
.page.nav-open .app-nav-bg {
    pointer-events: visible;
    opacity: 0.4;
}

@media screen and (min-width: 1024px) {
    .app-nav {
        position: static;
        background-color: transparent;
        height: unset;
        border-right: none;
        box-shadow: none;
        opacity: 1;
        transform: translateX(0px);
        pointer-events: visible;
    }
}
